import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../store/auth'
import common from '../store/common'
import chat from '../store/chat'
import news from '../store/news'
import help from '../store/help'
import places from '../store/places'
import users from '../store/users'
import manageNews from '../store/manage/news'
import manageHelp from '../store/manage/help'
import managePhotos from '../store/manage/photos'
import managePlaces from '../store/manage/places'
import manageInvestigation from '../store/manage/investigation'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        common,
        chat,
        news,
        help,
        places,
        users,
        manageNews,
        manageHelp,
        managePhotos,
        managePlaces,
        manageInvestigation,
    },
})
