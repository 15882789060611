import axios from 'axios'
import { uuidv4 } from '../helpers/strings'
import db from '../services/db'

const chat = {
    namespaced: true,

    state: {
        messages: [],
        offlineMessages: [],
        limit: Number.MAX_SAFE_INTEGER,
        workingQueue: false,
    },

    getters: {
        messagesWithOffline: (state) => {
            return [...state.messages, ...state.offlineMessages]
        },
    },

    mutations: {
        pushMessage(state, message) {
            const idx = state.offlineMessages.findIndex(
                (m) => m.sync_id === message.sync_id
            )
            if (idx !== -1) {
                state.offlineMessages.splice(idx, 1)
            }
            state.messages.push(message)
        },
        unshiftMessage(state, message) {
            state.messages.unshift(message)
        },
        setOfflineMessages(state, messages) {
            state.offlineMessages = messages
        },
        pushOfflineMessage(state, message) {
            state.offlineMessages.push(message)
        },
        setLimit(state, limit) {
            state.limit = limit
        },
        setWorkingQueue(state, working) {
            state.workingQueue = working
        },
        removeOfflineMessageOnIdx(state, idx) {
            state.offlineMessages.splice(idx, 1)
        },
    },

    actions: {
        getMessages({ state, commit, dispatch }) {
            return new Promise((resolve, reject) => {
                const newestId = state.messages.length
                    ? state.messages[state.messages.length - 1].id
                    : ''
                axios
                    .get(`/api/chat/${newestId}`)
                    .then((response) => {
                        const messages =
                            newestId === ''
                                ? response.data.messages
                                : response.data

                        if (newestId === '') {
                            commit('setLimit', response.data.limit)
                        }

                        for (
                            let index = messages.length - 1;
                            index >= 0;
                            index--
                        ) {
                            const message = messages[index]
                            commit('pushMessage', message)
                        }
                        resolve()
                    })
                    .catch((err) => reject(err))
                    .finally(() => {
                        dispatch('getOfflineMessages')
                    })
            })
        },

        getOlderMessages({ state, commit }) {
            return new Promise((resolve, reject) => {
                if (state.messages.length === 0) {
                    resolve()
                    return
                }

                const oldestId = state.messages[0].id

                axios
                    .get(`/api/chat/history/${oldestId}`)
                    .then((response) => {
                        response.data.forEach((message) => {
                            commit('unshiftMessage', message)
                        })
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        async getOfflineMessages({ commit }) {
            const messages = await db.offlineMessages.toArray()
            commit('setOfflineMessages', messages)
        },

        sendBaseMessage({ rootState, commit }, { message, fromOffline }) {
            return new Promise((resolve, reject) => {
                const sync_id = uuidv4()
                axios
                    .post('/api/chat/', { message, sync_id })
                    .then(() => {
                        resolve()
                    })
                    .catch((err) => {
                        if (err.code === 'ERR_NETWORK' && !fromOffline) {
                            // queued to db
                            const offlineMessage = {
                                content: message,
                                user_id: rootState.auth.user.id,
                                user: {
                                    nickname: rootState.auth.user.nickname,
                                },
                                sync_id,
                                waiting: true,
                            }
                            db.offlineMessages
                                .add(offlineMessage)
                                .then((id) => {
                                    commit('pushOfflineMessage', {
                                        id,
                                        ...offlineMessage,
                                    })
                                    resolve()
                                })
                                .catch(() => reject())
                        } else {
                            reject(err)
                        }
                    })
            })
        },

        sendMessage({ dispatch }, message) {
            return dispatch('sendBaseMessage', { message, fromOffline: false })
        },

        sendOfflineMessages({ state, dispatch, commit }, innerCall) {
            if (
                (state.workingQueue && !innerCall) ||
                state.offlineMessages.length === 0
            ) {
                return
            }

            const message = state.offlineMessages[0]
            dispatch('sendBaseMessage', {
                message: message.content,
                fromOffline: true,
            })
                .then(() => {
                    db.offlineMessages.delete(message.id)
                    commit('removeOfflineMessageOnIdx', 0)
                    if (state.offlineMessages.length > 0) {
                        // user can post 5 messages in 1 minute
                        setTimeout(() => {
                            dispatch('sendOfflineMessages', true)
                        }, 15 * 1000)
                    } else {
                        commit('setWorkingQueue', false)
                    }
                })
                .catch(() => {
                    commit('setWorkingQueue', false)
                })
        },
    },
}

export default chat
