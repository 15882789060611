const guards = {
    Users: ['admin', 'superadmin'],
    ManagePlaces: ['admin', 'superadmin'],
    ManagePhotos: ['manager', 'admin', 'superadmin'],
    ManageNews: ['manager', 'admin', 'superadmin'],
    ManageHelp: ['admin', 'superadmin'],
    ManageInvestigation: ['admin', 'superadmin'],
}

const platformRoutes = [
    {
        path: '/app',
        component: () =>
            import(/* webpackChunkName: "platform" */ '../layouts/Platform'),
        children: [
            {
                path: '/first-steps',
                name: 'Wizard',
                component: () =>
                    import(
                        /* webpackChunkName: "wizard" */ '../views/wizard/Index'
                    ),
            },
            {
                path: '/places/list',
                name: 'PlacesList',
                component: () =>
                    import(
                        /* webpackChunkName: "places" */ '../views/places/List'
                    ),
            },
            {
                path: '/places/map',
                name: 'PlacesMap',
                component: () =>
                    import(
                        /* webpackChunkName: "places" */ '../views/places/Map'
                    ),
            },
            {
                path: '/place/:id',
                name: 'PlaceIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "places" */ '../views/places/Index'
                    ),
                children: [
                    {
                        path: '',
                        name: 'PlaceDetail',
                        component: () =>
                            import(
                                /* webpackChunkName: "places" */ '../views/places/Detail'
                            ),
                    },
                    {
                        path: 'comments',
                        name: 'PlaceComments',
                        component: () =>
                            import(
                                /* webpackChunkName: "places" */ '../views/places/Comments'
                            ),
                    },
                ],
            },
            {
                path: '/chat',
                name: 'Chat',
                component: () =>
                    import(
                        /* webpackChunkName: "chat" */ '../views/chat/Index'
                    ),
            },
            {
                path: '/profile',
                name: 'ProfileView',
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ '../views/profile/View'
                    ),
            },
            {
                path: '/profile/edit',
                name: 'ProfileEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ '../views/profile/Edit'
                    ),
            },
            {
                path: '/leaderboards',
                name: 'Leaderboards',
                component: () =>
                    import(
                        /* webpackChunkName: "leaderboards" */ '../views/leaderboards/Index'
                    ),
            },
            {
                path: '/news',
                name: 'News',
                component: () =>
                    import(
                        /* webpackChunkName: "news" */ '../views/news/Index'
                    ),
            },
            {
                path: '/help',
                name: 'Help',
                component: () =>
                    import(
                        /* webpackChunkName: "help" */ '../views/help/Index'
                    ),
            },
            {
                path: '/users',
                name: 'Users',
                component: () =>
                    import(
                        /* webpackChunkName: "users" */ '../views/users/Index'
                    ),
                meta: {
                    roles: guards.Users,
                },
            },
            {
                path: '/manage/places/:id?',
                name: 'ManagePlaces',
                component: () =>
                    import(
                        /* webpackChunkName: "manage" */ '../views/manage/places/Index'
                    ),
                meta: {
                    roles: guards.ManagePlaces,
                },
            },
            {
                path: '/manage/photos',
                name: 'ManagePhotos',
                component: () =>
                    import(
                        /* webpackChunkName: "manage" */ '../views/manage/photos/Index'
                    ),
                meta: {
                    roles: guards.ManagePhotos,
                },
            },
            {
                path: '/manage/news',
                name: 'ManageNews',
                component: () =>
                    import(
                        /* webpackChunkName: "manage" */ '../views/manage/news/Index'
                    ),
                meta: {
                    roles: guards.ManageNews,
                },
            },
            {
                path: '/manage/help',
                name: 'ManageHelp',
                component: () =>
                    import(
                        /* webpackChunkName: "manage" */ '../views/manage/help/Index'
                    ),
                meta: {
                    roles: guards.ManageHelp,
                },
            },
            {
                path: '/manage/investigation',
                name: 'ManageInvestigation',
                component: () =>
                    import(
                        /* webpackChunkName: "manage" */ '../views/manage/investigation/Index'
                    ),
                meta: {
                    roles: guards.ManageInvestigation,
                },
            },
        ],
    },
]

export { guards }

export default platformRoutes
