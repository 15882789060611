import axios from 'axios'

const manageNews = {
    namespaced: true,

    state: {
        news: [],
    },

    mutations: {
        setNews (state, news) {
            state.news = news
        },

        addNewsItem (state, newsItem) {
            state.news.push(newsItem)
        },

        updateNewsItem (state, updatedNewsItem) {
            let idx = state.news.findIndex(newsItem => newsItem.id === updatedNewsItem.id)
            if (idx !== -1) {
                Object.assign(state.news[idx], updatedNewsItem)
            }
        },

        removeNewsItem (state, newsItemId) {
            let idx = state.news.findIndex(newsItem => newsItem.id === newsItemId)
            if (idx !== -1) {
                state.news.splice(idx, 1)
            }
        },
    },

    actions: {
        getData ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/api/manage/news')
                    .then(response => {
                        commit('setNews', response.data)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        insertNewsItem ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/manage/news', data)
                    .then(response => {
                        commit('addNewsItem', response.data)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        updateNewsItem ({ commit }, { newsItem, data }) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/manage/news/${newsItem.id}`, data)
                    .then(response => {
                        commit('updateNewsItem', response.data)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        removeNewsItem ({ commit }, newsItemId) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/manage/news/${newsItemId}`)
                    .then(() => {
                        commit('removeNewsItem', newsItemId)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
    }
}

export default manageNews
