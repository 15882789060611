import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/vue-router'
import store from './plugins/vuex'
import i18n from './plugins/vue-i18n'
import './plugins/vue-tour'
import './plugins/sentry'
import axios from 'axios'
import io from 'socket.io-client'

Vue.prototype.$http = axios
Vue.prototype.$io = io
Vue.prototype.$socket = io.connect(websocketAddr, {
    timeout: 1000,
    reconnectionDelay: 500,
    reconnectionDelayMax: 500,
    transports: ['polling'],
})

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
