import axios from 'axios'
import * as Sentry from '@sentry/browser'

function setTokenToAxios(token) {
    axios.defaults.headers.common['Authorization'] =
        token.token_type + ' ' + token.access_token
}

let token = localStorage.getItem('token')
if (token) {
    token = JSON.parse(token)
    setTokenToAxios(token)
}

const auth = {
    namespaced: true,

    state: {
        user: null,
        token,
        location: null,
        locationInterval: null,
        lastTourStep: 0,
        stores: [],
    },

    getters: {
        tokenLifetime: state => {
            if (state.token == null) {
                return NaN
            }

            let now = new Date().getTime()
            let issuedAt = state.token.issued_at
            let expiresIn = state.token.expires_in * 1000
            let reserve = 5 * 60 * 1000 // 5 minutes reserve to refresh token

            return issuedAt + expiresIn - now - reserve
        },

        userFullName: state => {
            if (state.user == null) {
                return ''
            }

            return state.user.firstname + ' ' + state.user.lastname
        },

        storeOptions: state => {
            return state.stores.map(store => ({
                text: `${store.code} — ${store.name}`,
                value: store.id,
            }))
        },
    },

    mutations: {
        updateToken(state, token) {
            let issued_at = new Date().getTime()
            state.token = {
                ...token,
                issued_at,
            }
            setTokenToAxios(token)
            localStorage.setItem('token', JSON.stringify(state.token))
        },

        removeToken(state) {
            state.token = null
            localStorage.removeItem('token')
        },

        setUser(state, user) {
            state.user = user
            Sentry.setUser({
                id: user.id,
                username: user.nickname,
                email: user.email,
            })
        },

        updateUser(state, payload) {
            state.user = {
                ...state.user,
                ...payload,
            }
        },

        setLocation(state, location) {
            state.location = location
        },

        setLocationInterval(state, interval) {
            state.locationInterval = interval
        },

        setLastTourStep(state, step) {
            state.lastTourStep = step
        },

        setStores(state, stores) {
            state.stores = stores
        },

        setLastReadNews(state, lastReadAt) {
            state.user.last_read_news_at = lastReadAt
        },
    },

    actions: {
        login({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/login', credentials)
                    .then(response => {
                        commit('updateToken', response.data)

                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        logout({ commit }) {
            commit('removeToken')
        },

        refreshToken({ state, getters, dispatch, commit }) {
            return new Promise((resolve, reject) => {
                if (state.token == null) {
                    reject('Missing token')
                }

                axios
                    .post('/api/token/refresh', {
                        refresh_token: state.token.refresh_token,
                    })
                    .then(response => {
                        commit('updateToken', response.data)

                        setTimeout(() => {
                            dispatch('refreshToken')
                        }, getters.tokenLifetime)

                        resolve()
                    })
                    .catch(err => {
                        dispatch('logout')
                    })
            })
        },

        register({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/register', credentials)
                    .then(response => {
                        commit('updateToken', response.data)

                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        me({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/me')
                    .then(response => {
                        commit('setUser', response.data)

                        setTimeout(() => {
                            dispatch('refreshToken')
                        }, getters.tokenLifetime)

                        resolve()
                    })
                    .catch(err => {
                        dispatch('logout')
                    })
            })
        },

        saveProfile({ commit }, profile) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/profile', profile)
                    .then(() => {
                        commit('updateUser', profile)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        finishWizard({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/finish-wizard')
                    .then(() => {
                        commit('updateUser', {
                            wizard_finished: true,
                        })

                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        getLocation({ commit, state }, force) {
            return new Promise((resolve, reject) => {
                if (!state.user.wizard_finished && !force) {
                    reject('Wizard not finished')
                    return
                }

                navigator.geolocation.getCurrentPosition(
                    position => {
                        commit('setLocation', position.coords)
                        resolve()
                    },
                    error => reject(error)
                )
            })
        },

        resendVerification() {
            return axios.get('/api/auth/resend-verification')
        },

        sendResetPasswordEmail({}, email) {
            return axios.post('/api/forgot-password', { email })
        },

        resetPassword({}, data) {
            return axios.post('/api/reset-password', data)
        },

        finishTour({ commit }, finished) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/finish-tour', { finished })
                    .then(() => {
                        commit('updateUser', {
                            tour_finished: finished,
                        })

                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        unlock({ commit }, password) {
            return new Promise((resolve, reject) => {
                const unique_id = localStorage.getItem('unique_id')
                axios
                    .post('/api/unlock', { password, unique_id })
                    .then(() => {
                        commit(
                            'common/setConfig',
                            { locked: false },
                            { root: true }
                        )

                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        getStores({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/auth/stores')
                    .then(response => {
                        commit('setStores', response.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    },
}

export default auth
