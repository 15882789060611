<template>
    <v-app>
        <v-fade-transition mode="out-in">
            <div class="loader" v-if="showLoader"></div>
            <router-view v-else></router-view>
        </v-fade-transition>

        <v-snackbar
            v-model="showUpdatePrompt"
            right
            top
            multi-line
            vertical
            timeout="-1"
        >
            {{ $t('message.update_available') }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    class="mr-4"
                    @click="showUpdatePrompt = false"
                >
                    {{ $t('ui.cancel') }}
                </v-btn>
                <v-btn
                    color="white"
                    class="black--text"
                    v-bind="attrs"
                    @click="reload"
                >
                    <v-icon left>mdi-refresh</v-icon> {{ $t('ui.update') }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'

const intervalUpdateSW =
    process.env.NODE_ENV === 'development' ? 60 * 1000 : 60 * 60 * 1000

export default {
    data() {
        return {
            showUpdatePrompt: false,
            refreshing: false,
        }
    },

    computed: mapState({
        showLoader: (state) => state.common.showLoader,
        locked: (state) => state.common.config.locked,
    }),

    created() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((registration) => {
                    setInterval(() => {
                        registration.update()
                    }, intervalUpdateSW)

                    registration.addEventListener('updatefound', () => {
                        const newRegistration = registration.installing
                        newRegistration.addEventListener('statechange', () => {
                            if (newRegistration.state === 'installed') {
                                // if there's an existing controller (previous Service Worker), show the prompt
                                if (navigator.serviceWorker.controller) {
                                    this.showUpdatePrompt = true
                                }
                            }
                        })
                    })
                })

            navigator.serviceWorker.addEventListener(
                'message',
                async (event) => {
                    // Optional: ensure the message came from workbox-broadcast-update
                    if (event.data.meta === 'workbox-broadcast-update') {
                        const { cacheName, updatedURL } = event.data.payload

                        if (
                            updatedURL.endsWith('/api/places') ||
                            updatedURL.endsWith('/api/help')
                        ) {
                            const cache = await caches.open(cacheName)
                            const updatedResponse = await cache.match(
                                updatedURL
                            )
                            const updatedText = await updatedResponse.text()
                            const response = JSON.parse(updatedText)

                            if (updatedURL.endsWith('/api/places')) {
                                this.$store.dispatch(
                                    'places/setPlacesFromResponse',
                                    response
                                )
                            } else if (updatedURL.endsWith('/api/help')) {
                                this.$store.commit('help/setItems', response)
                            }
                        }
                    }
                }
            )
        }

        this.$http.interceptors.response.use(undefined, (err) => {
            if (
                err.response &&
                err.response.status === 401 &&
                err.config.url !== '/api/login' &&
                err.config.url !== '/api/unlock' &&
                err.config.url !== '/api/me' &&
                err.config.url !== '/api/token/refresh'
            ) {
                this.$store.dispatch('auth/logout')
                window.location = '/auth/login'
            } else if (err.response && err.response.status === 423) {
                window.location = '/end'
            } else {
                return Promise.reject(err)
            }
        })

        this.$store.dispatch('common/getConfig').then(() => {
            if (
                this.locked &&
                window.location.pathname !== '/unlock' &&
                window.location.pathname !== '/end'
            ) {
                window.location = '/end'
            } else if (!this.locked && window.location.pathname === '/end') {
                window.location = '/'
            } else if (
                this.$store.state.auth.token !== null &&
                (window.location.pathname === '/' ||
                    window.location.pathname === '/auth/login')
            ) {
                this.$router.push({ name: 'PlacesList' }).catch(() => {})
            }
        })
    },

    methods: {
        reload() {
            this.showUpdatePrompt = false
            if (this.refreshing) {
                return
            }

            location.reload()
            this.refreshing = true
        },
    },
}
</script>
