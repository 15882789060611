const homeRoutes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ '../layouts/Home'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import(/* webpackChunkName: "home" */ '../views/home/Home')
            },
            {
                path: '/end',
                name: 'End',
                component: () => import(/* webpackChunkName: "home" */ '../views/home/End')
            }
        ]
    }
]

export default homeRoutes
