import axios from 'axios'

function setUniqueId(uniqueId) {
    axios.defaults.headers.common['Unique-Id'] = uniqueId

    localStorage.setItem('unique_id', uniqueId)
}

if (localStorage.getItem('unique_id') !== null) {
    setUniqueId(localStorage.getItem('unique_id'))
}

const common = {
    namespaced: true,

    state: {
        config: {
            locked: true,
            loaded: false,
            locked_message: '',
            show_not_register_alert: false,
            photo_points: 0,
            max_points_limit: 0,
        },
        title: '',
        showLoader: true,
        appBarPhoto: null,
        appBarTabs: [],
        appBarTabIndex: null,
        showPlaceFiltersButton: false,
        backButton: {
            show: false,
            parent: null,
        },
    },

    mutations: {
        setTitle(state, title) {
            state.title = title
            document.title = title + ' | Dáme Česko'
        },

        setShowLoader(state, show) {
            state.showLoader = show
        },

        setAppBarPhoto(state, url) {
            state.appBarPhoto = url
        },

        setShowPlaceFilterButton(state, show) {
            state.showPlaceFiltersButton = show
        },

        setAppBarTabs(state, tabs) {
            state.appBarTabs = tabs
            if (tabs.length) {
                state.appBarTabIndex = 0
            } else {
                state.appBarTabIndex = null
            }
        },

        setActiveAppBarTab(state, index) {
            state.appBarTabIndex = index
        },

        setConfig(state, config) {
            Object.keys(config).forEach((key) => {
                if (key in state.config) {
                    state.config[key] = config[key]
                }
            })
            state.config.loaded = true
        },

        setBackButton(state, { show, parent }) {
            state.backButton.show = show
            state.backButton.parent = parent
        },
    },

    actions: {
        showLoader({ commit }) {
            commit('setShowLoader', true)
        },

        hideLoader({ commit }) {
            commit('setShowLoader', false)
        },

        showPlaceFilterButton({ commit }) {
            commit('setShowPlaceFilterButton', true)
        },

        hidePlaceFilterButton({ commit }) {
            commit('setShowPlaceFilterButton', false)
        },

        getConfig({ commit, dispatch }) {
            dispatch('showLoader')
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/config')
                    .then((response) => {
                        commit('setConfig', response.data)
                        if (localStorage.getItem('unique_id') === null) {
                            setUniqueId(response.data.unique_id)
                        }
                        dispatch('hideLoader')
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        showBackButton({ commit }, parent) {
            commit('setBackButton', { show: true, parent })
        },

        hideBackButton({ commit }) {
            commit('setBackButton', { show: false })
        },
    },
}

export default common
