import { removeAccent } from './strings'

export const search = (needle, heystack) => {
    const words = needle.split(' ')
    for (let j = 0; j < words.length; j++) {
        const re = new RegExp(removeAccent(words[j]), 'i')
        if (removeAccent(heystack).search(re) === -1) {
            return false
        }
    }
    return true
}
