import axios from 'axios'
import dayjs from 'dayjs'

const news = {
    namespaced: true,

    state: {
        items: [],
        lastReadAt: null,
        loading: true,
    },

    getters: {
        unreadNews: (state, getters, rootState) => {
            const lastReadAt = rootState.auth.user.last_read_news_at

            return state.items.filter((item) =>
                dayjs(item.published_at).isAfter(lastReadAt)
            )
        },
    },

    mutations: {
        setItems(state, items) {
            state.items = items.sort((a, b) =>
                dayjs(a.published_at).isAfter(b.published_at) ? -1 : 1
            )
            state.loading = false
        },
        setLastReadAt(state, lastReadAt) {
            state.lastReadAt = lastReadAt
        },
    },

    actions: {
        getList({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/news')
                    .then((response) => {
                        commit('setItems', response.data.news)
                        commit('setLastReadAt', response.data.lastReadAt)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
        setRead({ commit, state }) {
            return new Promise((resolve, reject) => {
                const { lastReadAt } = state

                axios
                    .post('/api/news/set-read', {
                        timestamp: dayjs(lastReadAt).format(
                            'YYYY-MM-DDTHH:mm:ss+00:00'
                        ),
                    })
                    .then(() => {
                        commit('auth/setLastReadNews', lastReadAt, {
                            root: true,
                        })
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
        areSomeNew() {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/news/some-unread')
                    .then((response) => {
                        resolve(response.data.exists)
                    })
                    .catch((err) => reject(err))
            })
        },
    },
}

export default news
