import axios from 'axios'
import dayjs from 'dayjs'

const users = {
    namespaced: true,

    state: {
        users: [],
        leaderboards: [],
        loadingUserPoints: false,
        userPoints: {},
        userPointsByMonth: {},
    },

    getters: {
        getCurrentMonthPoints: (state) => () => {
            const currentMonthKey = dayjs().format('YYYY-MM')
            if (currentMonthKey in state.userPointsByMonth) {
                return state.userPointsByMonth[currentMonthKey]
            }
            return 0
        },
        checkExceededMaxPoints: (state, getters, rootState) => () => {
            const maxPointsLimit = rootState.common.config.max_points_limit
            if (maxPointsLimit === 0) {
                return false
            }
            return getters.getCurrentMonthPoints() >= maxPointsLimit
        },
    },

    mutations: {
        setUsers(state, users) {
            state.users = users
        },

        addUser(state, user) {
            state.users.push(user)
        },

        updateUser(state, updatedUser) {
            let idx = state.users.findIndex(
                (user) => user.id === updatedUser.id
            )
            if (idx !== -1) {
                Object.assign(state.users[idx], updatedUser)
            }
        },

        removeUser(state, userId) {
            let idx = state.users.findIndex((user) => user.id === userId)
            if (idx !== -1) {
                state.users.splice(idx, 1)
            }
        },

        setLeaderboards(state, leaderboards) {
            state.leaderboards = leaderboards
        },

        setLoadingUserPoints(state, loading) {
            state.loadingUserPoints = loading
        },

        setUserPoints(state, points) {
            const pointsObject = {}
            const pointsByMonth = {}
            points.forEach((pointsItem) => {
                if (!(pointsItem.place_id in pointsObject)) {
                    pointsObject[pointsItem.place_id] = {
                        visit: 0,
                        photo: 0,
                        question: 0,
                    }
                }

                pointsObject[pointsItem.place_id][pointsItem.type] =
                    pointsItem.points

                const monthKey = dayjs(pointsItem.created_at).format('YYYY-MM')

                if (!(monthKey in pointsByMonth)) {
                    pointsByMonth[monthKey] = 0
                }
                pointsByMonth[monthKey] += pointsItem.points
            })
            state.userPoints = pointsObject
            state.userPointsByMonth = pointsByMonth
        },
    },

    actions: {
        getList({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/users')
                    .then((response) => {
                        commit('setUsers', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        insertUser({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/users', data)
                    .then((response) => {
                        commit('addUser', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        updateUser({ commit }, { user, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/users/${user.id}`, data)
                    .then((response) => {
                        commit('updateUser', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        removeUser({ commit }, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/users/${userId}`)
                    .then(() => {
                        commit('removeUser', userId)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        switchDeactivate({ commit }, { userId, deactivate }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/users/switch-deactivate/${userId}`, {
                        deactivate,
                    })
                    .then((response) => {
                        commit('updateUser', response.data)
                        resolve(response.data)
                    })
                    .catch((err) => reject(err))
            })
        },

        getTop({ commit }, type) {
            commit('setLeaderboards', [])
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/users/top/${type || ''}`)
                    .then((response) => {
                        let users = response.data
                        users = users.sort((a, b) =>
                            a.place < b.place ? -1 : 1
                        )
                        commit('setLeaderboards', users)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        getUserPoints({ commit }) {
            return new Promise((resolve, reject) => {
                commit('setLoadingUserPoints', true)
                axios
                    .get('/api/profile/points')
                    .then((response) => {
                        commit('setUserPoints', response.data.points)
                        commit(
                            'common/setConfig',
                            {
                                max_points_limit:
                                    response.data.max_points_limit,
                            },
                            { root: true }
                        )
                        resolve()
                    })
                    .catch((err) => reject(err))
                    .finally(() => {
                        commit('setLoadingUserPoints', false)
                    })
            })
        },

        getUserLog(context, userId) {
            return axios.get(`/api/users/log/${userId}`)
        },

        getUserNotVisitedPlaces(context, userId) {
            return axios.get(`/api/users/not-visited-places/${userId}`)
        },

        makeManualVisit(context, data) {
            return axios.post('/api/users/manual-visit', data)
        },

        switchDeactivateVisit(context, { userId, placeId, deactivate }) {
            return axios.post(`/api/users/switch-deactivate-visit/${userId}`, {
                place_id: placeId,
                deactivate,
            })
        },

        switchVerifiedVisit(context, { userId, placeId, verified }) {
            return axios.post(`/api/users/switch-verified-visit/${userId}`, {
                place_id: placeId,
                verified,
            })
        },

        changeUserState({ commit }, { userId, state }) {
            return new Promise((resolve, reject) => {
                const request = state
                    ? axios.post(`/api/users/employee-state/${userId}`, {
                          state,
                      })
                    : axios.delete(`/api/users/employee-state/${userId}`)

                request
                    .then((response) => {
                        commit('updateUser', response.data)
                        resolve(response.data)
                    })
                    .catch((err) => reject(err))
            })
        },
    },
}

export default users
