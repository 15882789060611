/**
 * Remove accent characters from string
 *
 * @param str String
 *
 * @return string String without accent characters
 */
export const removeAccent = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Generate UUIDv4 string
 * @returns string UUIDv4 string
 */
export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    )
}
