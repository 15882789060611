/**
 * Returns the distance of two points with gps coordinates in meters. Uses `haversine` fromula.
 *
 * Haversine formula:
 * a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
 * c = 2 ⋅ atan2( √a, √(1−a) )
 * d = R ⋅ c
 * where φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
 * note that angles need to be in radians to pass to trig functions!
 *
 * @param pointA GPS coords of point A in { lat, lng } format
 * @param pointB GPS coords of point B in { lat, lng } format
 *
 * @return integer Distance in meters
 */
export const countDistance = (pointA, pointB) => {
    const R = 6371e3 // metres
    const phi1 = pointA.lat * Math.PI/180 // φ, λ in radians
    const phi2 = pointB.lat * Math.PI/180
    const deltaPhi    = (pointB.lat-pointA.lat) * Math.PI/180
    const deltaLambda = (pointB.lng-pointA.lng) * Math.PI/180

    const a = Math.sin(deltaPhi/2) * Math.sin(deltaPhi/2) +
              Math.cos(phi1) * Math.cos(phi2) *
              Math.sin(deltaLambda/2) * Math.sin(deltaLambda/2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

    const d = R * c // in metres

    return d
}

/**
 * Return distance in human redable format (m, km)
 *
 * @param distance integer Distance in meters
 *
 * @return string Formatted distance for better readability
 */
export const formatDistance = (distance) => {
    if (isNaN(distance)) {
        return '...&nbsp;km';
    }

    let unit = 'm'
    let precision = 1 // multiples of 10 increase the number of decimal places

    if (distance >= 1100) { // km
        distance /= 1000
        unit = 'km'
        precision = 10
    }

    return Math.round(distance * precision) / precision + '&nbsp;' + unit
}

/**
 * Convert place location to standard location object
 *
 * @param place
 *
 * @return object Location
 */
export const placeLocToStdLoc = place => {
    return {
        lat: parseFloat(place.coord_lat),
        lng: parseFloat(place.coord_long)
    }
}
