import axios from 'axios'

const help = {
    namespaced: true,

    state: {
        items: [],
        loading: true,
    },

    mutations: {
        setItems(state, items) {
            state.items = items.sort((a, b) =>
                a.position < b.position ? -1 : 1
            )
            state.loading = false
        },
    },

    actions: {
        getList({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/help')
                    .then((response) => {
                        commit('setItems', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
    },
}

export default help
