import axios from 'axios'

const managePhotos = {
    namespaced: true,

    state: {
        places: [],
    },

    mutations: {
        setPlaces(state, places) {
            state.places = places
        },

        removePhoto(state, photo) {
            const place = state.places.find((plc) => plc.id === photo.place_id)
            if (place) {
                const idx = place.photos.findIndex((pht) => pht.id === photo.id)
                if (idx !== -1) {
                    place.photos.splice(idx, 1)
                }
            }
        },

        updatePhoto(state, photo) {
            const place = state.places.find((plc) => plc.id === photo.place_id)
            console.log(place)
            if (place) {
                const idx = place.photos.findIndex((pht) => pht.id === photo.id)
                console.log(idx)
                if (idx !== -1) {
                    Object.assign(place.photos[idx], photo)
                }
            }
        },
    },

    actions: {
        getData({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/manage/photos')
                    .then((response) => {
                        commit('setPlaces', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        removePhoto({ commit }, photo) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/manage/photos/${photo.id}`)
                    .then(() => {
                        commit('removePhoto', photo)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        rotatePhoto({ commit }, { photoId, degrees }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/manage/photos/rotate/${photoId}`, {
                        degrees,
                    })
                    .then((response) => {
                        commit('updatePhoto', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
    },
}

export default managePhotos
