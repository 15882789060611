const cs = {
    message: {
        wrong_credentials: 'Nesprávné přihlašování údaje.',
        user_deactivated:
            'Váš účet byl deaktivován. Pro dotazy kontaktujte <a href="mailto:podpora@damecesko.cz?subject=Deaktivovaný účet">podpora@damecesko.cz</a>',
        communication_error:
            'Nastala chyba v komunikaci. Zkuste to prosím později.',

        cannot_get_users_list: 'Nepodařilo se získat seznam uživatelů.',
        cannot_delete_user: 'Nepodařilo se smazat uživatele.',
        cannot_deactivate_user: 'Nepodařilo se deaktivovat uživatele.',
        cannot_activate_user: 'Nepodařilo se aktivovat uživatele.',
        cannot_change_user_state: 'Nepodařilo se změnit status uživatele.',

        user_denied_the_request_for_geolocation:
            'Nepovolil jste zjišťování pozice.',
        location_information_is_unavailable:
            'Informace o pozici není dostupná.',
        the_request_to_get_user_location_timed_out:
            'Vypršela doba pro získání pozice.',
        an_unknown_error_occurred:
            'Neznámá chyba při zjišťování pozice. Zkuste to prosím později.',

        cannot_get_places_list: 'Nepodařilo se získat seznam míst',
        cannot_delete_place: 'Nepodařilo se smazat místo.',

        wrong_unlock_password: 'Nesprávné heslo',

        you_cant_check_here:
            'Ještě nejste na místě, zbývá vám: <strong>{distance}</strong>',
        check_here_is_limited:
            'Toto místo je možné zaspat pouze od <strong>{from}</strong> do <strong>{to}</strong>',
        you_can_not_check_at_this_time:
            'V tento čas nemůžete zapsat svou návštěvu',
        you_was_here: 'Toto místo jste již navštívil/a.',
        you_uploaded_photo: 'Získal/a jste body za nahrání fotografie.',
        you_can_answer_question_after_photo_uploaded:
            'Body vám načteme až po nahrání fotografie.',
        answered_correct: 'Otázku jste zodpověděl/a správně',
        answered_wrong: 'Otázku jste zodpověděl/a špatně',
        your_visit_was_deactivated:
            'Návštěva místa byla z důvodu porušení pravidel zrušena',

        checked:
            '<strong>Gratulujeme!</strong><br>Zapsal/a jste návštěvu tohoto místa. Body za návštěvu získáte až po nahrání alespoň jedné fotografie.',
        checked_with_points:
            '<strong>Gratulujeme!</strong><br>Zapsal/a jste návštěvu tohoto místa a&nbsp;získáváte <strong>{points}&nbsp;bodů</strong>.',
        checked_in_offline_mode:
            'Z důvodu špatného, nebo neexistujícího internetového připojení byla návštěva zapsána v režimu offline. Nahrát fotografii a odpovědět na doplňující otázku budete moci, až budete opět online a zápis bude odeslán na server.',
        answered_with_points:
            '<strong>Gratulujeme!</strong><br>Za správnou odpověď získáváte <strong>{points}&nbsp;bodů</strong>.',
        answered_without_points:
            '<strong>Škoda!</strong><br>To nebyla správná odpověď.',
        photo_uploaded_and_points_received:
            '<strong>Gratulujeme!</strong><br>Za návštěvu získáváte <strong>{visitPoints}&nbsp;bodů</strong>.<br>Za nahrání fotografie získáváte <strong>{photoPoints}&nbsp;bodů</strong>.',
        photo_uploaded_without_points:
            '<strong>Děkujeme za nahrání další fotografie!</strong>',

        cannot_delete_photo: 'Nepodařilo se smazat fotografii.',
        cannot_rotate_photo: 'Nepodařilo se otočit fotografii',
        no_results: 'žádné výsledky',
        no_records: 'žádné záznamy',

        cannot_get_user_log: 'Nepodařilo se načíst log uživatele',

        cannot_delete_news_item: 'Nepodařilo se smazat novinku.',

        cannot_delete_help_block: 'Nepodařilo se smazat položku nápoovědy',

        do_not_register_title: 'Soutěž ještě nebyla spuštěna!',
        do_not_register:
            'Nyní prosím <strong>NEPROVÁDĚJTE</strong> registrace ani přihlašování. Portál bude spuštěn v nejbližších dnech. Budete o tom včas informováni.',

        you_must_wait_to_send_new_message:
            'Musíte chvíli počkat, než budete moci odeslat další zprávu.',

        update_available:
            'K dispozici je nová aktualizace. Pro její načtení klikněte na tlačítko Aktualizovat.',

        no_internet_connection: 'Žádné, nebo špatné internetové připojení.',
        unknown_error: 'Nastala neznámá chyba. Zkuste to prosím později.',

        max_points_info_mini:
            'Již jste dosáhli maximálního počtu {maxPoints} bodů za tento měsíc.',
        max_points_info:
            'Již jste dosáhli maximálního počtu {maxPoints} bodů za tento měsíc. Na benefitní konto Vám bude připsáno {maxPoints} Kč. Veškeré body nad tento limit se již projevují pouze v tomto profilu a v žebříčku hráčů, ale nemají vliv na současné ani budoucí převody na benefitní konto. <a href="/dokumenty/Pravidla_souteze_DameCesko-2024.pdf" target="_blank" class="white--text font-weight-medium">Viz Pravidly hry DámeČesko</a>',
        go_to_my_profile: 'Zobrazit můj profil',

        cannot_toggle_deactivate_visit:
            'Nepodařilo se změnit stav deaktivace návětěvy',
        cannot_toggle_verify_visit:
            'Nepodařilo se změnit stav verifikace návštěvy',

        heic_file_not_supported:
            'Fotografie formátu HEIC nejsou podporovány. Zkuste ji převést na formát JPG.',
    },

    ui: {
        registration: 'Registrace',
        login_view: 'Přihlášení',

        close: 'Zavřít',
        cancel: 'Zrušit',
        save: 'Uložit',
        confirm: 'Potvrdit',
        continue: 'Pokračovat',
        back: 'Zpět',
        search: 'Hledat',
        open_app: 'Otevřít Dáme Česko',
        yes: 'Ano',
        no: 'Ne',
        i_understand: 'Rozumím',
        update: 'Aktualizovat',

        help: 'Nápověda a pravidla',
        restart_tour: 'Znova spustit průvodce',
        cookies_settings: 'Nastavení souborů cookie',
        privacy_policy: 'Zásady ochrany osobních údajů',

        login: 'Přihlásit se',
        logout: 'Odhlásit se',
        password: 'Heslo',
        repeat_password: 'Opakovat heslo',
        register: 'Registrovat se',
        login_with_google: 'Přihlásit se přes Google',
        register_with_google: 'Registrovat se přes Google',
        login_with_facebook: 'Přihlásit se přes Facebook',
        register_with_facebook: 'Registrovat se přes Facebook',
        or: 'nebo',

        forgot_password_view: 'Zapomenuté heslo',
        forgot_password: 'Zapomněl/a jsem heslo',
        your_registered_email: 'Váš registrovaný e-mail',
        send: 'Odeslat',

        reset_password_view: 'Obnovení hesla',
        new_password: 'Nové heslo',
        change_password: 'Změnit heslo',

        unlock_view: 'Odemknout aplikaci',
        unlock: 'Odemknout',

        nickname: 'Přezdívka',
        email: 'E-mail',
        role: 'Role',
        deactivated: 'Deaktivován',
        user_state: 'Status',
        created_at: 'Vytvořen',
        actions: 'Akce',

        add_user: 'Přidat uživatele',
        edit_user: 'Upravit uživatele',
        export_points: 'Exportovat body',
        export_users: 'Exportovat uživatele',
        export: 'Exportovat',
        date_range: 'Časové období',

        verify_email_address: 'Potvrzení e-mailové adresy',
        resend_verification_email: 'Znova odeslat verifikační e-mail',
        fill_profile: 'Vyplnění profilu',
        enable_location: 'Povolení pozice',
        i_agree_with_terms:
            'Uděluji <a href="/dokumenty/Souhlas_se_zpracovanim_Dame_Cesko_24.pdf" target="_blank">souhlas</a> se zpracováním osobních údajů a&nbsp;potvrzuji, že jsem se seznámil/a se <a href="/dokumenty/Zasady_zpracovani_osobnich_udaju_Penny_Dame_Cesko_24.pdf" target="_blank">Zásadami zpracování osobních údajů</a> a&nbsp;<a href="/dokumenty/Pravidla_souteze_DameCesko-2024.pdf" target="_blank">Pravidly hry</a>.',
        i_am_an_employee_of_pennymarket:
            'Jsem zaměstnancem <strong>Penny Market s.r.o.</strong>',
        company: 'Společnost',
        employee_number: 'Zaměstnanecké číslo',
        store: 'Pobočka',

        places_list: 'Seznam míst',
        filters: 'Filtry',
        filter_name: 'Hledat dle jména',
        filter_region: 'Filtrovat dle kraje',
        filter_category: 'Filtrovat dle kategorie',
        filter_favourite: 'Pouze oblíbená místa',
        filter_new: 'Pouze nová místa',
        filter_visited: 'Skrýt navštívená',
        filter_not_finished: 'Nedokončené návštěvy',
        total_places:
            '0 míst | {totalPlaces} místo | {totalPlaces} místa | {totalPlaces} míst',
        show_on_map: 'zobrazit na mapě',
        not_published: 'Neveřejné',
        is_new: 'Nové',
        most_visited: 'Nejnavštěvovanější',
        by_rates: 'Dle hodnocení',
        nearest: 'Nejbližší',
        my_favourite: 'Mé oblíbené',
        already_visited: 'Již navštíveno',
        photo_not_uploaded: 'Není nahrána fotka',
        question_not_answered: 'Není zodpovězena otázka',
        no_places: 'žádná místa',
        gallery: 'Galerie',
        map: 'Mapa',
        navigate: 'Navigovat',
        check_here: 'Zapsat návštěvu',
        waiting_to_sync: 'Zápis čeká na synchronizaci',
        points_for_visit: 'Bodů za návštěvu',
        points_for_quiz: 'Bodů za kvíz',
        answer_question: 'Zodpovědět otázku',
        question: 'Otázka',
        answer: 'Odpovědět',
        source: 'Zdroj',

        places_map: 'Mapa míst',
        show_detail: 'Zobrazit detaily',

        exif: 'EXIF',

        chat: 'Chat',
        write_message: 'Napsat zprávu',
        load_older_messages: 'Načíst starší zprávy',
        message_waiting: 'Čekám na odeslání...',
        message_sent: 'Odesláno',

        profile: 'Profil',
        visited_places: 'Navštívená místa',
        place_name: 'Název místa',
        your_visited_places: 'Tvá navštívená místa',
        you_are_not_visited_any: 'Zatím jste nenavštívil/a žádné místo.',
        total_points:
            'Nemáte žádné body | Celkem máte <strong>{totalPoints}</strong> bod | Celkem máte <strong>{totalPoints}</strong> body | Celkem máte <strong>{totalPoints}</strong> bodů',
        month_points:
            'Za tento měsíc máte <span class="font-weight-medium">{points}</span> bodů z&nbsp;{maxPoints} pro benefitní konto.<br><a href="/dokumenty/Pravidla_souteze_DameCesko-2024.pdf" target="_blank" class="white--text font-weight-medium">Viz Pravidly hry DámeČesko</a>',

        leaderboards: 'Žebříček',
        no_user: 'Žádný uživatel',
        you_are_not_in_leaderboards:
            'Zatím ještě nejste v tomto žebříčku. Navštivte nějaké místo a získejte body.',
        overall: 'Celkový',
        monthly: 'Měsíční',
        weekly: 'Týdenní',
        daily: 'Denní',
        find_me: 'Najdi mě',

        cookie_law_text:
            'Tento web používá k&nbsp;poskytování svých služeb soubory cookie. Používáním tohoto webu s&nbsp;tím vyjadřujete svůj souhlas.',
        i_agree: 'Souhlasím',

        description: 'Popis',
        label: 'Label',
        label_color: 'Barva labelu',
        region: 'Kraj',
        categories: 'Kategorie',
        state: 'Stav',
        coord_lat: 'Zeměpisná šířka (lat.)',
        coord_long: 'Zeměpisná délka (long.)',
        visit_points: 'Body za návštěvu',
        question_points: 'Body za správnou odpověď',
        correct_answer: 'Správná odpověď',
        author: 'Autor',
        ip: 'IP adresa',
        user_agent: 'User agent',
        device_unique_id: 'Unikátní ID zařízení',
        distance: 'Vzdálenost',
        accuracy: 'Přesnost',
        altitude: 'Nadm. výška',
        altitudeAccuracy: 'Přesnost nadm. výška',
        heading: 'Směr',
        speed: 'Rychlost',
        speed_from_last: 'Rychlost od minulé návštěvy',
        distance_from_last: 'Vzdálenost od minulé návštěvy',
        user: 'Uživatel',
        place: 'Místo',
        position: '{position}. místo',

        time: 'Čas',
        title: 'Nadpis',

        users: {
            title: 'Uživatelé',
            show_log: 'Zobrazit historii událostí',
            log: 'Historie událostí',
            registration_state: 'Stav registrace',
            tour: 'Průvodce',
            finished: 'Dokončeno',
            unfinished: 'Nedokončeno',
            registered_by: 'Registrován pomocí',
            registered_by_names: {
                app: 'Aplikace',
                google: 'Google',
                facebook: 'Facebook',
                seed: 'Programově',
                admin: 'Administrace',
            },
            created_at: 'Vytvořeno v',
            log_type: 'Typ logu',
            log_types: {
                visit: 'Návštěva místa',
                answer: 'Zodpovězení otázky',
                photo: 'Nahrání fotografie',
            },
            data: 'Data',
            photo_url: 'URL fotografie',
            make: 'Výrobce',
            model: 'Model',
            datetime: 'Datum a čas',
            resolution: 'Rozlišení',
            delete_photo: 'Smazat fotografii',
            deactivate_visit: 'Deaktivovat návštěvu',
            reactivate_visit: 'Reaktivovat návštěvu',
            visit_deactivated: 'Návštěva deaktivována',
            are_you_sure_deactivate_visit:
                'Opravdu chcete deaktivovat tuto návštěvu?',
            are_you_sure_reactivate_visit:
                'Opravdu chcete opět aktivovat tuto návštěvu?',
            answer: 'Odpověď',

            are_you_sure_change_state:
                'Opravdu chcete změnit status uživatele na <strong class="text-uppercase">{state}</strong>?',
            are_you_sure_remove_state:
                'Opravdu chcete zrušit status uživatele?',

            mark_as_suspicious: 'Označit za podezřelého',
            mark_as_hacker: 'Označit za hackera',
            mark_as_all_right: 'Označit za prověřeného',
            remove_state: 'Zrušit status',

            suspicious: 'Podezřelý',
            hacker: 'Hacker',
            'old-suspicious': 'Dříve podezřelý',
            'old-hacker': 'Dříve hacker',
            all_right: 'Prověřený',
            without_state: 'Bez stavu',

            deactivate: 'Deaktivovat',
            reactivate: 'Opět aktivovat',
            deactivate_user: 'Deaktivovat uživatele',
            reactivate_user: 'Opět aktivovat uživatele',
            create_visit: 'Zapsat návštěvu',
            edit: 'Upravit',
            delete: 'Smazat',

            visited_at_date: 'Datum návštěvy',

            verified_visit: 'Verifikovat návštěvu',
            unverified_visit: 'Zrušit verifikaci',

            are_you_sure_verified_visit:
                'Opravdu chcete verifikovat tuto návštěvu?',
            are_you_sure_unverified_visit:
                'Opravdu chcete zrušit verifikaci této návštěvy?',
        },

        places: {
            title: 'Místa',
            manage_places: 'Správa míst',
            add: 'Přidat místo',
            edit: 'Upravit místo',
            accuracy_form: 'Přesnost v metrech',
            place_info: 'Informace o místě',
            state_filter: 'Filtr stavu',
            export: 'Exportovat',
            open_map: 'Otevřít mapu',
            accuracy: 'Přesnost (m)',
            main_photo: 'Hlavní fotografie',
            other_photos: 'Ostatní fotografie',
            edit_categories: 'Upravit kategorie',
            add_category: 'Přidat kategorii',
            add_photo: 'Přidat fotografii',
            your_photos: 'Vaše fotografie',
            photo_from_user: 'Fotografie od uživatele',
            rate_place: 'Hodnotit toto místo',
            your_rating_comment: 'Sděl nám svůj názor (volitelné)',
            ratings: 'Hodnocení a recenze',
            no_ratings: 'žádná hodnocení',
            show_all_ratings: 'Zobrazit všechny recenze',
            your_review: 'Tvoje recenze',
            edit_review: 'Upravit recenzi',
            add_response: 'Přidat odpověď',
            edit_response: 'Upravit odpověď',
            response: 'Odpověď',
            answer_to_rate: 'Odpovědět na hodnocení',
            visit_is_limited: 'Čas návštěvy je limitován',
            visitable_from: 'Od',
            visitable_to: 'Do',
            is_visible: 'Je viditelné',
        },

        photos: {
            title: 'Fotografie',
            manage_user_photos_short: 'Správa uživ. fotografií',
            manage_user_photos: 'Správa uživatelských fotografií',
            count: 'žádná fotka|{count} fotka|{count} fotky|{count} fotek',
            search_place: 'Hledat fotky dle místa',
            search_user: 'Hledat fotky dle autora',
            group_by_place: 'Seskupit dle místa',
            deleted_user: 'Deleted user',
        },

        news: {
            manage_news: 'Správa novinek',
            add: 'Přidat novinku',
            edit: 'Upravit novinku',
            content: 'Obsah',
            state_filter: 'Filtr stavu',
            published_at: 'Zveřejněno',
            title: 'Novinky',
            news_overview: 'Přehled novinek',
            no_news: 'žádné novinky',
        },

        manage_help: {
            title: 'Správa nápovědy',
            position: 'Pozice',
            add: 'Přidat položku',
            edit: 'Úprava položky',
            type: 'Typ',
            types: {
                base: 'Prostý',
                tour_button: 'S tlačítkem průvodce',
                cookies: 'S cookies',
            },
            are_you_sure_delete_help_block:
                'Opravdu chcete tuto položku smazat?',
        },

        manage_investigation: {
            title: 'Investigace návštěv',
            active_columns: 'Aktivní sloupce',
            filter_by_user: 'Filtrovat dle uživatele',
            filter_by_place: 'Filtrovat dle místa',
            filter_by_user_state: 'Filtrovat dle uživ. stavu',
            filter_by_device: 'Filtrovat na zařízení s více uživ.',
            show_photos: 'Zobrazit fotky ({count})',
            visit_deactivated: 'Záznam deaktivován',
            user_deactivated: 'Uživ. deaktivován',
            suspicious_accuracy: 'Podezřelá přenost',
            suspicious_altitude: 'Podezřelá nadm. výška',
            suspicious_altitude_accuracy: 'Podezřelá přenost nadm. výsky',
            suspicious_speed: 'Podezřelá rychlost od minulé návš.',
            ip_address_whitelist: 'Ověřené IP adresy',
            verified_ip: 'Oveřená IP adresa',
            unverified_ips: 'Neověřené IP adresy',
        },

        hint: {
            change_only_if_filled: 'Změní se pouze pokud bude vyplněno',
            nickname:
                'Přezdívka smí obsahovat písmena, číslice, podtržítko a pomlčku',
        },

        roles: {
            user: 'Uživatel',
            manager: 'Manažer',
            admin: 'Administrátor',
            superadmin: 'Superadmin',
        },

        states: {
            all: 'Všechny stavy',
            draft: 'Návrh',
            testing: 'K testování',
            published: 'Zveřejněno',
            archived: 'Archivováno',
            summer22: 'Léto 22',
        },

        upload: {
            limit: 'Max. počet fotografií',
            placeholder: 'Přetáhněte sem soubory nebo je nahrajte kliknutím',
            cannot_upload: 'Nepodařilo se nahrát fotografii',
        },
    },

    page: {
        home: {
            welcome:
                '<strong>Poznávejte, objevujte, cestujte a užívejte si Česko.</strong><br>Inspirujte se našimi tipy na výlety a sbírejte body.',
        },
        login: {
            info:
                '<p>Letní pokračování soutěže DámeČesko <strong>startuje 1.7.2024</strong>.</p>' +
                '<p>Každý hráč si musí udělat pro ročník 2024 <strong>novou registraci</strong> (pro vyjádření souhlasu s novými <a href="/dokumenty/Pravidla_souteze_DameCesko-2024.pdf" target="_blank"><strong>pravidly</strong></a>).</p>' +
                '<p>Těšíme se na Vás.</p>',
        },
        wizard: {
            verify_email:
                'Na Vaši adresu {email} jsme odeslali e&#8209;mail pro ověření (Pokud Vám zpráva nedorazí, zkontrolujte i&nbsp;složku nevyžádané pošty). <strong>Po kliknutí na tlačítko v&nbsp;e&#8209;mailu</strong> můžete pokračovat dál v&nbsp;registraci účtu.',
            email_verified:
                'Výborně, Vaše adresa byla ověřena! Pokračuj dalším krokem registrace.',
            enable_location:
                '<span class="font-weight-bold red--text text--accent-4">A nyní pozor!</span> Pro správnou funkčnost aplikace <span class="font-weight-bold red--text text--accent-4">je nutné povolit přístup k&nbsp;poloze</span>. Po kliknutí na tlačítko POVOLENÍ POZICE se objeví dialogové okno prohlížeče, kde je třeba toto potvrdit kliknutím na POVOLIT.',
            enable_location2: 'Pojďme na to:',
            enable_location3: 'Okno může vypadat například takto:',
            location_enabled:
                'Výborně, pozice povolena. A to je celé. Užijte si Dáme Česko!',
        },
        users: {
            are_you_sure_delete_user: 'Opravdu chcete tohoto uživatele smazat?',
            are_you_sure_activate_user:
                'Opravdu chcete tohoto uživatele aktivovat?',
            are_you_sure_deactivate_user:
                'Opravdu chcete tohoto uživatele deaktivovat?',
        },
        places: {
            are_you_sure_delete_place: 'Opravdu chcete toto místo smazat?',
            are_you_sure_delete_category:
                'Opravdu chcete tuto kategorii smazat?',
            user_photo_points:
                'Vyfoťte a nahrajte fotografii tohoto místa a získejte díky tomu další body. Fotografií můžete nahrát více, ale body získáte pouze za první. Za první nahranou fotografii zíkáte <strong>50 bodů</strong>.',
        },
        photos: {
            are_you_sure_delete_photo: 'Opravdu chcete tuto fotografii smazat?',
        },
        news: {
            are_you_sure_delete_news: 'Opravdu chcete tuto novinku smazat?',
        },
        forgotten: {
            sent: '<p>Na Vámi zadaný e-mail byly odeslány pokyny pro obnovení hesla.</p><p>V případě problémů, nás prosím kontaktujte na: <a href="mailto:podpora@damecesko.cz">podpora@damecesko.cz</a></p>',
        },
        end: {
            locked: `Soutěž byla již ukončena.<br>Děkujeme Vám za účast.`,
            sign: `<em>Tým DámeČesko</em>`,
        },
    },

    validation: {
        required: 'Toto pole je povinné',
        email: 'E-mail musí mít správný formát',
        password_must_same: 'Hesla se musí shodovat',
        alpha_dash:
            'Položka smí obsahovat pouze písmena, číslice, podtržítko a pomlčku',
        approval_required: 'Musíte souhlasit s podmínkami',
        part_of_company_required: 'Musíte být zaměstnancem Penny Market s.r.o.',
        employee_number_format: 'Číslo zaměstnance musí být 2-6 číslic',
        store_required: 'Musíte vybrat pobočku',
        date_range: 'Musíte vybrat časové období',
        positive_integer: 'Položka musí obsahovat kladné celé číslo',
        photos_not_uploaded: 'Nejsou nahrány všechny fotografie',
    },

    tour: {
        labels: {
            buttonSkip: 'Přeskočit',
            buttonPrevious: 'Předchozí',
            buttonNext: 'Další',
            buttonStop: 'Ukončit',
        },
        step1: 'Navštěvujte zajímavá místa v Česku a sbírejte body na své Benefitní konto. Body získáte za návštěvu a nahrání fotografie. Další pak za správnou odpověď na kvízovou otázku.',
        step2: 'U každého místa také vidíte, v jakém jste kraji ...',
        step3: '... a jak jste od něj právě daleko.',
        step4: 'Můžete si o místě něco zajímavého přečíst ...',
        step5: '... a podívat se na fotografie.',
        step6: 'A až tam dorazíte - ZAPIŠTE NÁVŠTĚVU!',
        step7: 'Můžete si také prohlédnout mapu, svůj profil a žebříček všech hráčů.',
    },
}

export default cs
