import Vue from 'vue'
import Router from 'vue-router'
import store from '../plugins/vuex'
import authRoutes from '../routes/auth'
import platformRoutes from '../routes/platform'
import homeRoutes from '../routes/home'

Vue.use(Router)

homeRoutes[0].children.push(...authRoutes)

const router = new Router({
    mode: 'history',
    routes: [...platformRoutes, ...homeRoutes],
})

router.beforeEach((to, from, next) => {
    store.dispatch('common/showLoader')
    store.dispatch('common/hidePlaceFilterButton')
    store.commit('common/setAppBarPhoto', null)
    store.commit('common/setAppBarTabs', [])

    if (
        store.state.common.config.loaded &&
        store.state.common.config.locked &&
        to.matched.every(
            record => record.name !== 'Unlock' && record.name !== 'End'
        )
    ) {
        next({ name: 'End' })
        return
    }

    if (
        to.matched.every(
            record =>
                record.name !== 'Login' &&
                record.name !== 'Register' &&
                record.name !== 'Home' &&
                record.name !== 'ForgotPassword' &&
                record.name !== 'ResetPassword' &&
                record.name !== 'Unlock' &&
                record.name !== 'End'
        ) &&
        store.state.auth.token === null
    ) {
        next({ name: 'Home' })
        return
    }

    next()
})

router.afterEach((to, from) => {
    store.dispatch('common/hideLoader')
})

export default router
