import axios from 'axios'

const managePlaces = {
    namespaced: true,

    state: {
        places: [],
        regions: [],
        regionsMap: {},
        categories: []
    },

    mutations: {
        setPlaces (state, places) {
            state.places = places
        },

        setRegions (state, regions) {
            state.regions = regions
            state.regionsMap = {}
            regions.forEach(region => {
                state.regionsMap[region.id] = region.name
            })
        },

        setCategories (state, categories) {
            state.categories = categories
        },

        addPlace (state, place) {
            state.places.push(place)
        },

        updatePlace (state, updatedPlace) {
            let idx = state.places.findIndex(place => place.id === updatedPlace.id)
            if (idx !== -1) {
                Object.assign(state.places[idx], updatedPlace)
            }
        },

        removePlace (state, placeId) {
            let idx = state.places.findIndex(place => place.id === placeId)
            if (idx !== -1) {
                state.places.splice(idx, 1)
            }
        },

        addCategory (state, category) {
            state.categories.push(category)
        },

        updateCategory (state, updatedCategory) {
            let idx = state.categories.findIndex(category => category.id === updatedCategory.id)
            if (idx !== -1) {
                Object.assign(state.categories[idx], updatedCategory)
            }
        },

        removeCategory (state, categoryId) {
            let idx = state.categories.findIndex(category => category.id === categoryId)
            if (idx !== -1) {
                state.categories.splice(idx, 1)
            }
        },

        sortCategories (state) {
            state.categories.sort((a, b) => a.name.localeCompare(b.name))
        }
    },

    actions: {
        getData ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/api/manage/places')
                    .then(response => {
                        commit('setPlaces', response.data.places)
                        commit('setRegions', response.data.regions)
                        commit('setCategories', response.data.categories)
                        commit('sortCategories')
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        insertPlace ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/manage/places', data)
                    .then(response => {
                        commit('addPlace', response.data)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        updatePlace ({ commit }, { place, data }) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/manage/places/${place.id}`, data)
                    .then(response => {
                        commit('updatePlace', response.data)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        removePlace ({ commit }, placeId) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/manage/places/${placeId}`)
                    .then(() => {
                        commit('removePlace', placeId)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        insertCategory ({ commit }, name) {
            return new Promise((resolve, reject) => {
                axios.post('/api/manage/categories', { name })
                    .then(response => {
                        commit('addCategory', response.data)
                        commit('sortCategories')
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        updateCategory ({ commit }, { category, name }) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/manage/categories/${category.id}`, { name })
                    .then(response => {
                        commit('updateCategory', response.data)
                        commit('sortCategories')
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },

        removeCategory ({ commit }, categoryId) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/manage/categories/${categoryId}`)
                    .then(() => {
                        commit('removeCategory', categoryId)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
    }
}

export default managePlaces
