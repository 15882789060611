import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cs from '../langs/cs'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'cs',
    messages: {
        cs
    },
    pluralizationRules: {
        cs: (choice, choicesLength) => {
            if (choice <= 1) {
                return choice
            }

            if (choice <= 4) {
                return 2
            }

            return 3
        }
    }
})

export default i18n
