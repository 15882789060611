import axios from 'axios'
import Vue from 'vue'
import { sumBy } from 'lodash'
import { countDistance } from '../helpers/location'
import { search } from '../helpers/filter'
import db from '../services/db'
import dayjs from 'dayjs'

const PLACES_FILTERS_KEY = 'places_filters'

const remapPhotos = (photos) => {
    const photosObj = {
        main: null,
        others: [],
        workingQueue: false,
    }

    photos.forEach((photo) => {
        if (photo.type === 'main') {
            photosObj.main = photo
        } else {
            photosObj.others.push(photo)
        }
    })

    return photosObj
}

const getLocationObject = (location) => {
    return {
        coord_lat: location.latitude,
        coord_long: location.longitude,
        accuracy: location.accuracy,
        altitude: location.altitude,
        altitudeAccuracy: location.altitudeAccuracy,
        heading: location.heading,
        speed: location.speed,
    }
}

const places = {
    namespaced: true,

    state: {
        items: [],
        regions: [],
        categories: [],
        loaded: false,
        filters: JSON.parse(localStorage.getItem(PLACES_FILTERS_KEY)) || {
            isNew: false,
            hideVisited: true,
            notFinished: false,
        },
        prioritizeCategory: 5,

        offlineVisits: {},
    },

    mutations: {
        setPlaces(state, items) {
            state.items = items.map((place) => {
                return {
                    ...place,
                    photos: remapPhotos(place.photos),
                }
            })
            state.loaded = true
        },

        setRegions(state, regions) {
            state.regions = regions
        },

        setCategories(state, categories) {
            state.categories = categories
        },

        setVisited(state, placeId) {
            const idx = state.items.findIndex((item) => item.id === placeId)
            if (idx !== -1) {
                state.items[idx].visited = true
                state.items[idx].visited_at = dayjs().toISOString()
            }
        },

        setAnswered(state, { place, isCorrect }) {
            const idx = state.items.findIndex((item) => item.id === place.id)
            if (idx !== -1) {
                state.items[idx].answered = true
                state.items[idx].correctAnswered = isCorrect
            }
        },

        addFilters(state, filters) {
            state.filters = {
                ...state.filters,
                ...filters,
            }

            localStorage.setItem(
                PLACES_FILTERS_KEY,
                JSON.stringify(state.filters)
            )
        },

        removeFilter(state, filter) {
            Vue.delete(state.filters, filter)

            localStorage.setItem(
                PLACES_FILTERS_KEY,
                JSON.stringify(state.filters)
            )
        },

        setFavourite(state, { place, isFavourite }) {
            const idx = state.items.findIndex((item) => item.id === place.id)
            if (idx !== -1) {
                state.items[idx].isFavourite = isFavourite
            }
        },

        updatePhotos(state, { place, photos }) {
            const idx = state.items.findIndex((item) => item.id === place.id)
            if (idx !== -1) {
                state.items[idx].photos = remapPhotos(photos)
                state.items[idx].photoUploaded = true
            }
        },

        addRating(state, { place, rate }) {
            const idx = state.items.findIndex((item) => item.id === place.id)
            if (idx !== -1) {
                state.items[idx].rated = true

                const rateIdx = state.items[idx].rates.findIndex(
                    (pRate) => pRate.id === rate.id
                )

                if (rateIdx !== -1) {
                    state.items[idx].rates[rateIdx].stars = rate.stars
                    state.items[idx].rates[rateIdx].comment = rate.comment
                } else {
                    state.items[idx].rates.unshift(rate)
                }

                state.items[idx].rate =
                    sumBy(state.items[idx].rates, 'stars') /
                    state.items[idx].rates.length
            }
        },

        updateRateResponse(state, rate) {
            const placeIdx = state.items.findIndex(
                (item) => item.id === rate.place_id
            )
            if (placeIdx !== -1) {
                const rateIdx = state.items[placeIdx].rates.findIndex(
                    (pRate) => pRate.id === rate.id
                )

                if (rateIdx !== -1) {
                    state.items[placeIdx].rates[rateIdx].response =
                        rate.response
                }
            }
        },

        pushOfflineVisit(state, visit) {
            Vue.set(state.offlineVisits, visit.place_id, visit)
        },

        setOfflineVisits(state, visits) {
            const offlineVisits = {}
            visits.forEach((visit) => (offlineVisits[visit.place_id] = visit))
            state.offlineVisits = offlineVisits
        },

        removeFromOfflineVisits(state, placeId) {
            if (placeId in state.offlineVisits) {
                Vue.delete(state.offlineVisits, placeId)
            }
        },

        setWorkingQueue(state, working) {
            state.workingQueue = working
        },
    },

    getters: {
        itemsWithDistance: (state, getters, rootState) => {
            return state.items.map((place) => {
                let distance = undefined
                const userLocation = rootState.auth.location

                if (userLocation) {
                    distance = countDistance(
                        {
                            lat: place.coord_lat,
                            lng: place.coord_long,
                        },
                        {
                            lat: userLocation.latitude,
                            lng: userLocation.longitude,
                        }
                    )
                }

                return {
                    ...place,
                    distance,
                }
            })
        },

        filteredItemsWithDistance: (state, getters) => {
            const keys = Object.keys(state.filters)

            return getters.itemsWithDistance.filter((place) => {
                if (place.state === 'archived') {
                    return false
                }

                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i]
                    if (key === 'name') {
                        if (!search(state.filters[key], place.name)) {
                            return false
                        }
                    } else if (key === 'category_ids') {
                        return place[key].some(
                            (cat_id) =>
                                state.filters[key].indexOf(cat_id) !== -1
                        )
                    } else if (key === 'hideVisited') {
                        if (state.filters[key] && place.visited) {
                            return false
                        }
                    } else if (key === 'notFinished') {
                        if (
                            state.filters[key] &&
                            (!place.visited ||
                                (place.answered && place.photoUploaded))
                        ) {
                            return false
                        }
                    } else if (key === 'isNew') {
                        if (state.filters[key] && !place.isNew) {
                            return false
                        }
                    } else if (place[key] !== state.filters[key]) {
                        return false
                    }
                }
                return true
            })
        },

        getPlaceById: (state) => (id) =>
            state.items.find((place) => place.id === id),

        visited: (state) => {
            return state.items.filter((item) => item.visited)
        },

        checkIfOfflineVisited: (state) => (placeId) =>
            placeId in state.offlineVisits,

        offlineVisitedPlaces: (state, getters) => {
            return state.items.filter((place) =>
                getters.checkIfOfflineVisited(place.id)
            )
        },
    },

    actions: {
        setPlacesFromResponse({ commit }, response) {
            commit('setRegions', response.regions)
            const places = response.places
            commit('setPlaces', places)
            places.forEach((place) => {
                if (place.visited) {
                    commit('removeFromOfflineVisits', place.id)
                }
            })
            commit('setCategories', response.categories)
        },

        getList({ dispatch }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/places')
                    .then((response) => {
                        dispatch('setPlacesFromResponse', response.data)
                            .then(() => resolve())
                            .catch((err) => reject(err))
                    })
                    .catch((err) => reject(err))
                    .finally(() => {
                        dispatch('getOfflineVisits')
                    })
            })
        },

        getListIfNotLoaded({ state, dispatch }) {
            if (state.loaded) {
                return new Promise((resolve) => resolve())
            }
            return dispatch('getList')
        },

        async getOfflineVisits({ commit }) {
            const visits = await db.offlineVisits.toArray()
            commit('setOfflineVisits', visits)
        },

        baseVisit(
            { commit, getters },
            { place_id, location, timestamp, fromOffline }
        ) {
            return new Promise((resolve, reject) => {
                const data = {
                    ...location,
                }

                if (fromOffline) {
                    data.from_offline = true
                    data.timestamp = timestamp
                }

                axios
                    .post(`/api/places/visit/${place_id}`, data)
                    .then(() => {
                        commit('setVisited', place_id)
                        commit('removeFromOfflineVisits', place_id)
                        resolve(true)
                    })
                    .catch((err) => {
                        if (err.code === 'ERR_NETWORK' && !fromOffline) {
                            if (getters.checkIfOfflineVisited(place_id)) {
                                resolve(false)
                            }

                            // queued to db
                            const offlineVisit = {
                                place_id,
                                location,
                                timestamp: new Date().getTime(),
                            }
                            db.offlineVisits
                                .add(offlineVisit)
                                .then((id) => {
                                    commit('pushOfflineVisit', {
                                        id,
                                        ...offlineVisit,
                                    })
                                    resolve(false)
                                })
                                .catch((err) => {
                                    reject(err)
                                })
                        } else if (
                            // visit was already saved
                            err &&
                            err.response &&
                            err.response.status === 409
                        ) {
                            commit('setVisited', place_id)
                            commit('removeFromOfflineVisits', place_id)
                            resolve(true)
                        } else {
                            reject(err)
                        }
                    })
            })
        },

        visit({ commit, dispatch }, { place, location }) {
            return dispatch('baseVisit', {
                place_id: place.id,
                location: getLocationObject(location),
            })
        },

        sendOfflineVisits({ state, commit, dispatch }, innerCall) {
            const placesIds = Object.keys(state.offlineVisits)
            if ((state.workingQueue && !innerCall) || placesIds.length === 0) {
                return
            }

            const visit = state.offlineVisits[placesIds.shift()]
            dispatch('baseVisit', {
                ...visit,
                fromOffline: true,
            })
                .then(() => {
                    db.offlineVisits.delete(visit.id)
                    commit('removeFromOfflineVisits', visit.place_id)
                    if (placesIds.length > 0) {
                        dispatch('sendOfflineVisits', true)
                    } else {
                        commit('setWorkingQueue', false)
                    }
                })
                .catch(() => {
                    commit('setWorkingQueue', false)
                })
        },

        getQuestion({}, place) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/places/question/${place.id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((err) => reject(err))
            })
        },

        answerQuestion({ commit }, { place, question, answer }) {
            return new Promise((resolve, reject) => {
                const data = {
                    answer,
                }

                axios
                    .post(`/api/places/answer/${question.id}`, data)
                    .then((response) => {
                        const isCorrect = response.data.is_correct
                        commit('setAnswered', { place, isCorrect })
                        resolve(isCorrect)
                    })
                    .catch((err) => reject(err))
            })
        },

        setFavourite({ commit }, { place, isFavourite }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/places/favourite/${place.id}`, {
                        is_favourite: isFavourite,
                    })
                    .then(() => {
                        commit('setFavourite', { place, isFavourite })
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        attachNewPhotos({ commit }, { place, photoIds }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/places/attach-photos/${place.id}`, {
                        photo_ids: photoIds,
                    })
                    .then((response) => {
                        commit('updatePhotos', {
                            place,
                            photos: response.data.photos,
                        })
                        resolve(response.data.info)
                    })
                    .catch((err) => reject(err))
            })
        },

        rate({ commit }, { place, stars, comment }) {
            return new Promise((resolve, reject) => {
                const data = {
                    stars,
                    comment,
                }

                axios
                    .post(`/api/places/rate/${place.id}`, data)
                    .then((response) => {
                        const rate = {
                            ...response.data.rate,
                            user: response.data.user,
                        }
                        commit('addRating', { place, rate })
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        response({ commit }, { rate, response }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/places/response/${rate.id}`, { response })
                    .then((response) => {
                        commit('updateRateResponse', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
    },
}

export default places
