import axios from 'axios'

const manageInvestigation = {
    namespaced: true,

    state: {
        visits: [],
        users: {},
        places: {},
        deviceIdsWithMoreUsers: [],
        ips: [],
    },

    getters: {
        usersArray: (state) =>
            Object.values(state.users).sort((a, b) =>
                a.nickname.localeCompare(b.nickname)
            ),
        placesArray: (state) =>
            Object.values(state.places).sort((a, b) =>
                a.name.localeCompare(b.name)
            ),
    },

    mutations: {
        setVisits(state, visits) {
            state.visits = visits
        },
        setPlaces(state, places) {
            const placesMap = {}
            places.forEach((place) => {
                placesMap[place.id] = place
            })
            state.places = placesMap
        },
        setUsers(state, users) {
            const usersMap = {}
            users.forEach((user) => {
                usersMap[user.id] = user
            })
            state.users = usersMap
        },
        setDeviceIds(state, ids) {
            state.deviceIdsWithMoreUsers = ids
        },
        changeUserState(state, user) {
            if (user.id in state.users) {
                state.users[user.id].employee_state = user.employee_state
            }
        },
        changeUserDeactivation(state, user) {
            if (user.id in state.users) {
                state.users[user.id].deactivated = user.deactivated
            }
        },
        changeVisitDeactivation(state, { placeId, userId, deactivate }) {
            const idx = state.visits.findIndex(
                (visit) =>
                    visit.user_id === userId && visit.place_id === placeId
            )
            if (idx !== -1) {
                state.visits[idx].deactivated = deactivate
            }
        },
        changeVisitVerification(state, { placeId, userId, verified }) {
            const idx = state.visits.findIndex(
                (visit) =>
                    visit.user_id === userId && visit.place_id === placeId
            )
            if (idx !== -1) {
                state.visits[idx].verified = verified
            }
        },
        setIPs(state, ips) {
            state.ips = ips
        },
        addIP(state, item) {
            state.ips.push(item)
        },
        removeIP(state, item) {
            const idx = state.ips.findIndex((_item) => _item.id === item.id)
            if (idx !== -1) {
                state.ips.splice(idx, 1)
            }
        },
    },

    actions: {
        getData({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/manage/investigation')
                    .then((response) => {
                        commit('setVisits', response.data.visits)
                        commit('setPlaces', response.data.places)
                        commit('setUsers', response.data.users)
                        commit(
                            'setDeviceIds',
                            response.data.deviceIdsWithMoreUsers
                        )
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
        getIPAddressWhitelist({ commit }) {
            return axios
                .get('/api/manage/investigation/ips')
                .then((response) => {
                    commit('setIPs', response.data)
                })
        },
        addIPToWhitelist({ commit }, ip) {
            return axios
                .post('/api/manage/investigation/ips', { ip })
                .then((response) => {
                    commit('addIP', response.data)
                })
        },
        removeIPFromWhitelist({ commit }, item) {
            return axios
                .delete(`/api/manage/investigation/ips/${item.id}`)
                .then(() => {
                    commit('removeIP', item)
                })
        },
    },
}

export default manageInvestigation
