import axios from 'axios'

const manageHelp = {
    namespaced: true,

    state: {
        items: [],
        loading: true,
    },

    mutations: {
        setItems(state, items) {
            state.items = items.sort((a, b) =>
                a.position < b.position ? -1 : 1
            )
            state.loading = false
        },
        setLoading(state, loading) {
            state.loading = loading
        },
    },

    actions: {
        getList({ commit }) {
            commit('setLoading', true)
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/manage/help')
                    .then((response) => {
                        commit('setItems', response.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        insertHelpBlock({ dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/manage/help', data)
                    .then((response) => {
                        dispatch('getList')
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        updateHelpBlock({ dispatch }, { helpBlock, data }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/manage/help/${helpBlock.id}`, data)
                    .then((response) => {
                        dispatch('getList')
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        removeHelpBlock({ dispatch }, helpBlockId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/manage/help/${helpBlockId}`)
                    .then(() => {
                        dispatch('getList')
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        moveUp({ dispatch }, helpBlock) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/manage/help/up/${helpBlock.id}`)
                    .then((response) => {
                        dispatch('getList')
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },

        moveDown({ dispatch }, helpBlock) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/manage/help/down/${helpBlock.id}`)
                    .then((response) => {
                        dispatch('getList')
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
    },
}

export default manageHelp
