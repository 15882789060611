const authRoutes = [
    {
        path: '/auth',
        component: () =>
            import(/* webpackChunkName: "auth" */ '../layouts/Auth'),
        children: [
            {
                path: 'login/:err?',
                name: 'Login',
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/auth/Login'
                    ),
            },
            {
                path: 'register',
                name: 'Register',
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/auth/Register'
                    ),
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/auth/ForgotPassword'
                    ),
            },
            {
                path: 'reset-password/:token',
                name: 'ResetPassword',
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/auth/ResetPassword'
                    ),
            },
            {
                path: '/unlock',
                name: 'Unlock',
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/auth/Unlock'
                    ),
            },
        ],
    },
]

export default authRoutes
