import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import cs from 'vuetify/src/locale/cs.ts'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#bd4419',
                secondary: '#1D92AE',
                accent: '#C9B756',
            },
        },
    },
    lang: {
        locales: { cs },
        current: 'cs',
    },
}

const vuetify = new Vuetify(opts)

export default vuetify
